import React from 'react';
import '../assets/css/manuscriptsform.css'; // Ensure this file has necessary styling

const ManuscriptsFormClosed = () => {
  return (
    <div className='closed-container'>
      <h1>Application for Manuscript Grant is Closed</h1>
    </div>
  );
};

export default ManuscriptsFormClosed;
